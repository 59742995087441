import {
  Box,
  Typography,
  useTheme,
  Grid,
  IconButton,
  Dialog,
  Snackbar,
  Alert,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem,
  DialogContentText,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditNoteIcon from "@mui/icons-material/EditNote";
import getUser from "../../api/getUser";
import deleteUser from "../../api/deleteUser";
import editUser from "../../api/editUser";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import React from "react";
import { useEffect, useState } from "react";
import editUserCredential from "../../api/editCredentialUser";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogEditCredential, setOpenDialogEditCredential] =
    useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [reload, setReload] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUser();
        // console.log(result);
        setUsers(result);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, [reload]);

  const handleDialogDelete = (user) => {
    setSelectedUser(user);
    setOpenDialogDelete(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteUser(id);
      setUsers(users.filter((user) => user.id !== id));
      setSnackbar({
        open: true,
        message: "User deleted successfully!",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to delete user!",
        severity: "error",
      });
      console.error("Failed to delete user:", error);
    }
    handleCloseDialogDelete();
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
    setSelectedUser(null);
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setOpenDialogEdit(true);
  };

  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
    setSelectedUser(null);
  };

  const handleEditCredential = (user) => {
    setSelectedUser(user);
    setOpenDialogEditCredential(true);
  };

  const handleCloseDialogEditCredential = () => {
    setOpenDialogEditCredential(false);
    setSelectedUser(null);
  };

  const handleSaveUser = async () => {
    const updatedUser = selectedUser;
    try {
      await editUser(
        updatedUser.firstname,
        updatedUser.lastname,
        updatedUser.email,
        updatedUser.level,
        updatedUser.id
      );
      setSnackbar({
        open: true,
        message: "User Update successfully!",
        severity: "success",
      });
      setReload(!reload);
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Failed to update user!",
        severity: "error",
      });
      console.error("Error creating user:", err);
    }

    handleCloseDialogEdit();
  };

  const handleSaveUserCredential = async () => {
    const updatedUser = selectedUser;
    try {
      await editUserCredential(
        updatedUser.username,
        updatedUser.password,
        updatedUser.id
      );

      setSnackbar({
        open: true,
        message: "User Update successfully!",
        severity: "success",
      });
      setReload(!reload);
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Failed to update user!",
        severity: "error",
      });
      console.error("Error creating user:", err);
    }

    handleCloseDialogEditCredential();
  };

  const validateForm = () => {
    if (!selectedUser) return false;
    const { firstname, lastname, email } = selectedUser;
    return firstname && lastname && email;
  };

  const validateFormCredential = () => {
    if (!selectedUser) return false;
    const { username, password } = selectedUser;
    return username && password;
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const personCard = (user) => {
    return (
      <Box
        sx={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
          padding: "16px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          textAlign: "start",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: colors.primary[500] }}
            >
              {user.firstname} {user.lastname}
            </Typography>
            <Typography sx={{ color: colors.primary[500] }}>
              Username: {user.username}
            </Typography>
            <Typography sx={{ color: colors.primary[500] }}>
              Email: {user.email}
            </Typography>
            <Typography sx={{ color: colors.primary[500] }}>
              Level: {user.level}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() => handleDialogDelete(user)}
              sx={{ color: "#d84315" }}
            >
              <DeleteForeverIcon />
            </IconButton>
            <IconButton
              onClick={() => handleEditCredential(user)}
              sx={{ color: "#f9a825" }}
            >
              <EditNoteIcon />
            </IconButton>
            <IconButton
              onClick={() => handleEdit(user)}
              sx={{ color: "green" }}
            >
              <AccountBoxIcon />
            </IconButton>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mt="5px">
          <Box
            height="20px"
            width="100%"
            sx={{
              backgroundColor:
                user.level === 0 ? colors.grey[500] : colors.greenAccent[500],
              borderRadius: "10px",
            }}
          ></Box>
          <Typography
            ml="5px"
            fontWeight="bold"
            sx={{ color: colors.primary[500] }}
          >
            {user.level === 0 ? "User" : "Admin"}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box m="20px">
      <Header title="TEAM" subtitle="Managing the Them manager" />
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {users.map((user) => (
          <Grid item xs={4} sm={4} md={4} key={user.id}>
            {personCard(user)}
          </Grid>
        ))}
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Dialog open={openDialogEdit} onClose={handleCloseDialogEdit}>
        <DialogTitle>
          Edit User : {selectedUser?.firstname || ""}{" "}
          {selectedUser?.lastname || ""}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="First Name"
            fullWidth
            value={selectedUser?.firstname || ""}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, firstname: e.target.value })
            }
            required
          />
          <TextField
            margin="dense"
            label="Last Name"
            fullWidth
            value={selectedUser?.lastname || ""}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, lastname: e.target.value })
            }
            required
          />
          {/* <TextField
            margin="dense"
            label="Username"
            fullWidth
            value={selectedUser?.username || ""}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, username: e.target.value })
            }
            required
          /> */}
          <TextField
            margin="dense"
            label="Email"
            fullWidth
            value={selectedUser?.email || ""}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, email: e.target.value })
            }
            required
          />
          {/* <TextField
            margin="dense"
            label="password"
            fullWidth
            value={selectedUser?.password || ""}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, password: e.target.value })
            }
            required
          /> */}
          <FormControl fullWidth margin="dense">
            <Select
              value={selectedUser?.level ?? ""}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, level: e.target.value })
              }
            >
              <MenuItem value={0}>User</MenuItem>
              <MenuItem value={1}>Admin</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialogEdit}
            variant="outlined"
            sx={{
              color: "black",
              backgroundColor: "white",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveUser}
            variant="outlined"
            sx={{
              color: validateForm === true ? colors.grey[200] : "black",
              backgroundColor:
                validateForm === true
                  ? colors.greenAccent[200]
                  : colors.greenAccent[500],
            }}
            disabled={!validateForm()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogEditCredential}
        onClose={handleCloseDialogEditCredential}
      >
        <DialogTitle>
          Edit User credentials : {selectedUser?.firstname || ""}{" "}
          {selectedUser?.lastname || ""}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Username"
            fullWidth
            value={selectedUser?.username || ""}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, username: e.target.value })
            }
            required
          />
          <TextField
            margin="dense"
            label="password"
            fullWidth
            value={selectedUser?.password || ""}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, password: e.target.value })
            }
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialogEditCredential}
            variant="outlined"
            sx={{
              color: "black",
              backgroundColor: "white",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveUserCredential}
            variant="outlined"
            sx={{
              color:
                validateFormCredential === true ? colors.grey[200] : "black",
              backgroundColor:
                validateFormCredential === true
                  ? colors.greenAccent[200]
                  : colors.greenAccent[500],
            }}
            disabled={!validateFormCredential()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogDelete} onClose={handleCloseDialogDelete}>
        <DialogTitle>
          <Box display="flex" alignItems="center" alignContent="flex-start">
            <ErrorIcon sx={{ fontSize: "30px", color: "#f9a825" }} />
            <Typography ml="10px">Delete Account</Typography>
          </Box>
        </DialogTitle>
        <DialogContentText ml="20px" paddingRight="30px">
          Are you sure to delete account
        </DialogContentText>
        <DialogContentText
          ml="20px"
          mt="5px"
          paddingRight="30px"
          paddingBottom="20px"
          sx={{ color: colors.redAccent[500], fontWeight: "bold" }}
          textAlign="center"
        >
          {selectedUser?.firstname || ""} {selectedUser?.lastname || ""}
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={handleCloseDialogDelete}
            variant="outlined"
            sx={{
              color: "black",
              backgroundColor: "white",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(selectedUser?.id || "")}
            variant="outlined"
            sx={{
              color: colors.grey[900],
              backgroundColor: colors.redAccent[500],
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Team;
