import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useState, useEffect } from "react";

import Topbar from "./page/global/Topbar";
import Sidebar from "./page/global/Sidebar";
import Team from "./page/team";
import Form from "./page/form";
import SignInSide from "./page/login/Signin";

const ProtectedRoute = ({ isLoggedIn, children }) => {
  return isLoggedIn ? children : <Navigate to="/login" />;
};

function App() {
  const [theme, colorMode] = useMode();
  const [isLoggedIn, setIsLoggedIn] = useState();
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    const storedLoginStatus = localStorage.getItem("isLoggedIn");
    if (storedLoginStatus === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
    navigate("/login");
  };

  const isLoginPage = location.pathname === "/login";

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {!isLoginPage && isLoggedIn && <Sidebar/>}
          <main className="content">
            {!isLoginPage && isLoggedIn && (
              <Topbar handleLogout={handleLogout} />
            )}
            <Routes>
              <Route
                path="/login"
                element={<SignInSide setIsLoggedIn={setIsLoggedIn} />}
              />
              <Route
                path="/Profile"
                element={
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <Form />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Team"
                element={
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <Team />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
