import axios from "axios";
const editUserCredential = async (username, password, id) => {
  const data = JSON.stringify({
    username,
    password,
  });

  const config = {
    method: "put",
    url: `${process.env.REACT_APP_AUTH_URL}edit/credentials/${id}`,
    // url: `http://localhost:8000/edit/credentials/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error saving data:", error);
    throw error;
  }
};
export default editUserCredential;
