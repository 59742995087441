import React, { useContext } from "react";
import { Box, IconButton, useTheme} from "@mui/material";
import { ColorModeContext} from "../../theme";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";

function Topbar({ handleLogout }) {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  return (
    <Box display="flex" justifyContent="flex-end" p={2}>
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={handleLogout}>
          {theme.palette.mode === "dark" ? <LogoutIcon /> : <LogoutIcon />}
        </IconButton>
      </Box>
    </Box>
  );
}

export default Topbar;
