import axios from "axios";
const getUser = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_AUTH_URL}getuser`);
    return response.data;
  } catch (error) {
    console.error("Error saving data:", error);
    console.clear();
    throw error;
  }
};

export default getUser;
