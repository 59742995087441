import axios from "axios";
const createUser = async (
  firstname,
  lastname,
  username,
  email,
  password,
  level
) => {
  const data = JSON.stringify({
    firstname,
    lastname,
    username,
    email,
    password,
    level,
  });

  const config = {
    method: "post",
    url:`${process.env.REACT_APP_AUTH_URL}create`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error saving data:", error);
    throw error;
  }
};
export default createUser;
