import axios from "axios";
const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_AUTH_URL}delete/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error saving data:", error);
    console.clear();
    throw error;
  }
};

export default deleteUser;
