import React, { useState, useContext } from "react";
import { ColorModeContext} from "../../theme";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  CssBaseline,
  Link,
  Paper,
  Grid,
  LinearProgress,
  Typography,
  Button,
  Box,
  TextField,
  IconButton,
  useTheme,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      User management Cooling Tower Inpsection platform V.1.0.0 Powered by Drone
      Ai Co.,LTD.{" "}
    </Typography>
  );
}

export default function SignInSide({ setIsLoggedIn }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const loading = false;
  const failedLogin = false;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    if (username === process.env.REACT_APP_ADMIN_USERNAME && password === process.env.REACT_APP_ADMIN_PASSWORD) {
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", "true");
      navigate("/Team");
    } else {
      alert("Invalid username or password");
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://img2.pic.in.th/pic/signin.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box display="flex" alignContent="flex-end" mt="10px">
          <Box width="90%">

          </Box>
          <Box width="10%">
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: 128,
            }}
            display="flex"
            alignItems="center"
          >
            <PersonIcon sx={{ fontSize: "60px" }} />
            <Typography variant="h5" fontWeight="bold" ml="20px">
              User management platform
            </Typography>
          </Box>

          <br />

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            {failedLogin ? (
              <Alert severity="error" sx={{ marginTop: 1 }}>
                Invalid identifier or password.
              </Alert>
            ) : null}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleLogin}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2"></Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
            <br />
            <Box sx={{ width: "100%" }}>
              {loading ? <LinearProgress color="secondary" /> : null}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
