import axios from "axios";
const editUser = async (firstname, lastname, email, level, id) => {
  const data = JSON.stringify({
    firstname,
    lastname,
    email,
    level,
  });

  const config = {
    method: "put",
    url: `${process.env.REACT_APP_AUTH_URL}edit/profile/${id}`,
    // url: `http://localhost:8000/edit/profile/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error saving data:", error);
    throw error;
  }
};
export default editUser;
