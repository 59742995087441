import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Drawer,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemButton,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Box display="flex">
      {/* Drawer from Material-UI */}
      <Drawer
        variant="permanent"
        open={!isCollapsed}
        sx={{
          width: isCollapsed ? "80px" : "250px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isCollapsed ? "80px" : "250px",
            boxSizing: "border-box",
            backgroundColor: colors.primary.main,
            transition: "width 0.3s ease",
            overflowX: "hidden",
          },
        }}
      >
        {/* Drawer Content */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="16px"
        >
          {!isCollapsed && (
            <Typography variant="h3" color={colors.primary.contrastText}>
              ADMIN
            </Typography>
          )}
          <IconButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            sx={{ color: colors.primary.contrastText, ml: 0.5 }}
          >
            <MenuOutlinedIcon
              sx={{
                color: isCollapsed
                  ? colors.primary.light
                  : colors.primary.contrastText,
              }}
            />
          </IconButton>
        </Box>
        <Divider />

        {/* User Section */}
        {!isCollapsed && (
          <Box textAlign="center" my="16px">
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                alt="Profile-user"
                width="100px"
                height="100px"
                src={`https://img2.pic.in.th/pic/Untitled-designf0baadd8881d60eb.jpg`}
                style={{ cursor: "pointer", borderRadius: "50%" }}
              />
            </Box>
            <Typography
              variant="h5"
              color={colors.primary.contrastText}
              mt="8px"
              fontWeight="bold"
            >
              User management
            </Typography>
            <Typography variant="subtitle1" color={colors.primary.light}>
              South Bangkok Power Plant
            </Typography>
          </Box>
        )}

        {/* Menu Items */}
        <List>
          {/* Manage Team */}
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                minHeight: 48,
                display: "flex",
                justifyContent: isCollapsed ? "" : "initial",
                px: isCollapsed ? 2 : 2,

                "&:hover": isCollapsed
                  ? {
                      backgroundColor: colors.primary.light,
                      cursor: "pointer",
                      borderRadius: "50px",
                    }
                  : {
                      backgroundColor: colors.primary.light,
                      cursor: "pointer",
                    },
              }}
              onClick={() => navigate("/Team")}
            >
              <PeopleOutlinedIcon
                sx={{
                  color: colors.primary.contrastText,
                  ml: isCollapsed ? "10px" : "0px",
                }}
              />
              {!isCollapsed && (
                <Typography
                  variant="body1"
                  color={colors.primary.contrastText}
                  ml="16px"
                >
                  Manage Team
                </Typography>
              )}
            </ListItemButton>
          </ListItem>
          {/* Profile Form */}
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                minHeight: 48,
                display: "flex",
                justifyContent: isCollapsed ? "" : "initial",
                px: isCollapsed ? 2 : 2,

                "&:hover": isCollapsed
                  ? {
                      backgroundColor: colors.primary.light,
                      cursor: "pointer",
                      borderRadius: "50px",
                    }
                  : {
                      backgroundColor: colors.primary.light,
                      cursor: "pointer",
                    },
              }}
              onClick={() => navigate("/Profile")}
            >
              <PersonAddAltIcon
                sx={{
                  color: colors.primary.contrastText,
                  ml: isCollapsed ? "10px" : "0px",
                }}
              />
              {!isCollapsed && (
                <Typography
                  variant="body1"
                  color={colors.primary.contrastText}
                  ml="16px"
                >
                  Profile Form
                </Typography>
              )}
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar ;
